<template>
  <v-container
    class="profile-update-container scroll-container"
    id="container_gy"
  >
  </v-container>
</template>
<style lang="scss">
.profile-update-container {
  label {
    font-weight: 600;
    text-transform: uppercase;
  }
  .v-autocomplete {
    box-shadow: none;
    -webkit-box-shadow: none;
    input {
      font-size: 16px !important;
    }
  }
  .disclaimer-checkbox {
    .v-label {
      font-size: 12px !important;
    }
  }
}
</style>
<script>
import { showToaster } from "@/commons/notifications";
import { mapActions, mapGetters } from "vuex";
import customService from "@/service/customService";
import AddressSelector from "@/components/delivery/AddressSelector.vue";
export default {
  name: "ProfileUpdate",
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    })
  },
  methods: {
    ...mapActions({
      doLogin: "cart/doLogin",
      loadCart: "cart/loadCart"
    }),
    async setDeliveryService(deliveryServiceId) {
      var defaultConfig = {
        waitForResult: false,
        width: 530,
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        deliveryServiceId: deliveryServiceId
      };
      var config = Object.assign({}, null, defaultConfig);
      this.dialogInstance = await this.$dialog.show(AddressSelector, config);
    },
    async registerWithFidelity() {
      let profile = await customService.registerWithFidelity(
        this.fidelityCard,
        this.lastName
      );
      if (profile?.response?.status === 0) {
        this.$router.push({
          name: "RegistrationUser",
          params: { profile }
        });
        document.querySelector(".vuedl-layout__closeBtn").click();
      }
    },
    async openDeliveryServiceSelector() {
      this.setDeliveryService(1);
      document.querySelector(".vuedl-layout__closeBtn").click();
    },
    async handleSubmit(username, password) {
      let token = null;
      if (this.$recaptchaLoaded) {
        await this.$recaptchaLoaded();
        token = await this.$recaptcha("login");
      }
      if (password.length > 0) {
        try {
          const resLogin = await this.doLogin({
            email: username,
            password: password,
            rememberMe: this.rememberMe,
            token
          });
          window.localStorage.removeItem("loginId");
          if (this.isAuthenticated) {
            global.EventBus.$emit("login");
            this.$emit("loggedIn", true);
          }
          this.$emit("submit", true);
          if (resLogin && resLogin.user?.fidelityCard?.length > 0) {
            this.$router.push("/");
          } else {
            this.$router.push({ name: "ProfileUpdateInternal" });
          }
          setTimeout(() => {
            const closeButtons = document.querySelectorAll(
              ".vuedl-layout__closeBtn"
            );

            closeButtons.forEach(button => {
              button.click();
            });
          }, 600);
        } catch (response) {
          this.response = response;
        } finally {
          this.loading = false;
        }
      }
      window.localStorage.removeItem("loginId");
    },
    handleRegister() {
      this.$router.push({
        name: "RegistrationUser",
        path: "/registration-user"
      });
      this.$emit("submit", false);
    },
    loadGigyaScreenSet() {
      let there = this;
      const gigyaInstance = window.gigya;

      if (gigyaInstance) {
        gigyaInstance.accounts.addEventHandlers({
          onLogin: there.onLoginHandler,
          onLogout: there.onLogoutHandler
        });
        let preloadContainer = document.getElementById("gigya-preload");

        if (preloadContainer && preloadContainer.innerHTML.length > 0) {
          preloadContainer.style.display = "block";
          // Move the children elements (not just copying the innerHTML)
          this.$refs.loginDiv.appendChild(preloadContainer);
        } else {
          console.log("Preload container is empty");
          this.$nextTick(() => {
            gigyaInstance.accounts.showScreenSet({
              screenSet: "Iperdrive-RegistrationLogin",
              startScreen: "gigya-login-screen",
              showTermsLink: false,
              containerID: "loginDiv",
              onLogin: there.onLoginHandler,
              onError: error => {
                console.error("bbb Error loading Gigya screenset", error);
              }
            });
          });
        }
      } else {
        console.log("bbb Login.vue NO Gigya instance");
        this.$nextTick(() => {
          gigyaInstance.accounts.showScreenSet({
            screenSet: "Iperdrive-RegistrationLogin",
            startScreen: "gigya-login-screen",
            showTermsLink: false,
            containerID: "loginDiv",
            onLogin: there.onLoginHandler,
            onError: error => {
              console.error("bbb Error loading Gigya screenset", error);
            }
          });
        });
      }
    },
    onLoginHandler(response) {
      let uid = response.UID;
      let uidSig = response.UIDSignature;
      let sigTStamp = response.signatureTimestamp;
      let pwd = `${uidSig}:${sigTStamp}`;
      const loginId = window.localStorage.getItem("loginId");
      if (!loginId) {
        this.handleSubmit(uid, pwd);
        window.localStorage.setItem("loginId", uid);
      }
    }
  },
  async mounted() {
    let vm = this;
    console.log("bbb", vm.$route.query);
    const script = document.createElement("script");
    script.src = `https://cdns.eu1.gigya.com/js/gigya.js?apikey=${process.env.VUE_APP_GIGYA_API_KEY}&lang=it`;

    script.async = true;
    const successMsg = this.$t("profile.updateSuccessMessage");
    script.onload = function() {
      // eslint-disable-next-line no-undef
      this.gigya = gigya;

      var _this = this;

      if (vm.profile) {
        console.log("USER PASSED!: " + vm.profile);
      }

      function GigyaLoadFunctions() {
        console.log("Loading Gigya screen set...");
        _this.gigya.accounts.showScreenSet({
          containerID: "container_gy",
          screenSet: "Iperdrive-RegistrationLogin",
          startScreen: "gigya-reset-password-screen",
          loginID: vm?.user?.login,
          passwordResetToken: vm.$route?.query?.pwrt,
          onAfterScreenLoad: function(event) {
            console.log("Screen loaded:", event);
          },
          onAfterSubmit: function(eventObject) {
            if (eventObject.response.errorCode === 0) {
              showToaster(
                "success",
                { message: successMsg },
                {
                  position: "top-center",
                  duration: 3000,
                  iconPack: "mdi",
                  theme: "bubble",
                  keepOnHover: true,
                  top: true,
                  action: {
                    icon: "$clear"
                  }
                }
              );
              window.scroll({ top: 0, behavior: "smooth" });
            }
          },
          onError: function(eventObject) {
            console.log("ERROR:", eventObject);
            if (eventObject.errorCode == 400009) {
              var customMessage =
                eventObject.response.info.response.validationErrors[0];
              var message_new = "";
              if (customMessage.fieldName == "data") {
                var messageTemp = customMessage.customMessage;
                var code_start = messageTemp.indexOf("SF Response Code :");
                var code_end = messageTemp.indexOf("SF Response Message :");
                if (code_start !== -1) {
                  message_new = messageTemp.substring(code_end + 21).trim();
                } else {
                  code_start = messageTemp.indexOf("Codice SF :");
                  code_end = messageTemp.indexOf("Messaggio SF :");
                  if (code_start !== -1) {
                    message_new = messageTemp.substring(code_end + 14).trim();
                  }
                }
                if (message_new != "") {
                  message_new = message_new.replaceAll(
                    /\[([^\]]+)\]/g,
                    '<a href="$1">$1</a>'
                  );
                  var message_div = document.getElementsByClassName(
                    "gigya-error-msg gigya-form-error-msg gigya-error-code-400009 gigya-error-msg-active"
                  );

                  setTimeout(() => {
                    Array.prototype.forEach.call(message_div, function(div) {
                      div.innerHTML = message_new;
                    });
                  }, 1000);
                }
              }
            }
          }
        });
      }

      GigyaLoadFunctions();
    };
    document.head.appendChild(script);

    let there = this;
    const gigyaInstance = window.gigya;

    if (gigyaInstance) {
      gigyaInstance.accounts.addEventHandlers({
        onLogin: there.onLoginHandler,
        onLogout: there.onLogoutHandler
      });
    }
  }
};
</script>
